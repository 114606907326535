import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useTheme } from './ThemeManager';
import { Header } from './Header';
import { Footer } from './Footer';
import { HashRouter } from "react-router-dom";
import { Menu } from './Menu';
import { Router } from './Router';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Wrapper, SkipLink } from './styles/AppStyles';

const App = () => {
  const theme = useTheme();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={{ mode: theme.mode }}>
        <HashRouter>
          <Wrapper>
            <SkipLink href="#main-content">Skip to main content</SkipLink>
            <Header />
            <Menu />
            <main id="main-content" role="main" aria-label="Main content">
              <Router />
            </main>
            <Footer />
          </Wrapper>
        </HashRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
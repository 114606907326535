import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from './styles/GlobalStyles';

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <GlobalStyles />
      <App />
    </HelmetProvider>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';

// Define a type for the slice state
interface UiState {
  menuBarStatus: number;
  menuBarTransition: number;
}

// Define the initial state using that type
const initialState: UiState = {
  menuBarStatus: 0,
  menuBarTransition: 0
};

export const uiSlice = createSlice({
  name: 'ui',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setMenuBarStatus: (state, action: PayloadAction<number>) => {
      state.menuBarStatus = action.payload;
    },
    setMenuBarTransition: (state, action: PayloadAction<number>) => {
      state.menuBarTransition = action.payload;
    }
  }
});

export const { setMenuBarStatus, setMenuBarTransition } = uiSlice.actions;

// Other code could use the imported `RootState` type
export const selectMenuBarStatus = (state: RootState) => state.ui.menuBarStatus;
export const selectMenuBarTransition = (state: RootState) => state.ui.menuBarTransition;

export default uiSlice.reducer;

import styled from 'styled-components';
import { mediaH1, mediaH2, mediaH3 } from '../media';
import { textColor, buttonHoverColor } from '../theme';

export const Wrapper = styled.div<any>`
  background-color: #fcfcfc;
  color: #07486e;
  min-height: 100vh;
  text-align: left;
  transition: background-color 0.35s;
  h4 {
    font-size: 0.95rem;
    line-height: 1.4em;
  }
  p {
    font-size: 0.95rem;
  }
  h1, h2, h3, h4, p {
    margin: 1rem;
    width: 100%;
  }
  a {
    color: ${textColor};
    text-decoration: none;
    @media(hover: hover) and (pointer: fine) {
      transition: all 0.15s;
      &:hover {
        color: ${buttonHoverColor};
      }
    }
  }
  ${mediaH1}
  ${mediaH2}
  ${mediaH3}
`;

// Skip to main content link for accessibility
export const SkipLink = styled.a`
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;
  overflow: hidden;
  z-index: 9999;
  
  &:focus, &:active {
    color: #fff;
    background-color: #07486e;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: auto;
    margin: 10px;
    padding: 15px;
    border-radius: 3px;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
  }
`;

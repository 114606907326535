import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const ErrorContainer = styled.div`
  padding: 2rem;
  margin: 2rem auto;
  max-width: 800px;
  background-color: #fff8f8;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
`;

const ErrorHeading = styled.h2`
  color: #d32f2f;
  margin-top: 0;
`;

const ErrorMessage = styled.p`
  color: #333;
  margin-bottom: 1.5rem;
`;

const ErrorDetails = styled.details`
  margin-top: 1.5rem;
  
  summary {
    cursor: pointer;
    color: #666;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  pre {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    overflow: auto;
    font-size: 0.85rem;
    line-height: 1.5;
  }
`;

const ReloadButton = styled.button`
  background-color: #07486e;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #053654;
  }
`;

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
      errorInfo: null
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo
    });
    
    // Log error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleReload = (): void => {
    window.location.reload();
  };

  render(): ReactNode {
    const { hasError, error, errorInfo } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      // Custom fallback UI
      if (fallback) {
        return fallback;
      }

      return (
        <ErrorContainer role="alert">
          <ErrorHeading>Something went wrong</ErrorHeading>
          <ErrorMessage>
            We're sorry, but an error occurred while rendering this part of the page.
          </ErrorMessage>
          <ReloadButton onClick={this.handleReload}>
            Reload Page
          </ReloadButton>
          
          {(error || errorInfo) && (
            <ErrorDetails>
              <summary>Technical Details</summary>
              {error && <p>{error.toString()}</p>}
              {errorInfo && (
                <pre>
                  {errorInfo.componentStack}
                </pre>
              )}
            </ErrorDetails>
          )}
        </ErrorContainer>
      );
    }

    return children;
  }
} 
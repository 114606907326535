import React from 'react';
import styled from 'styled-components';
import { ReactComponent as MenuSVG } from '../img/menu.svg';

const Wrapper = styled.span<any>`
  cursor: pointer;
  margin: 0 0.5rem 0 1rem;
  
  svg {
    fill: #559004;
    width: 100%;
    max-width: 25px;

    &:hover {
      opacity: 0.8;
    }
  }
  path {
    stroke: #559004;
    fill: #559004;
  }
`;

export const MenuToggle: React.FC<any> = (props) => {

  return (
    <Wrapper>
      <MenuSVG />
    </Wrapper>
  );
}

export default MenuToggle;
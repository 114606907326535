import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.mode === 'light' ? '#fafafa' : '#0e0f11'};
`;

const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 3px solid rgba(112, 175, 26, 0.1);
  border-radius: 50%;
  border-top-color: #70af1a;
  animation: ${spin} 0.8s linear infinite;
`;

export const LoadingFallback: React.FC = () => {
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
};

export default LoadingFallback; 
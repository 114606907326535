import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('/fonts/opensans-semibold-webfont.woff2') format('woff2'),
      url('/fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap; /* Improved font loading */
  }

  @font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('/fonts/opensans-extrabold-webfont.woff2') format('woff2'),
      url('/fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap; /* Improved font loading */
  }
  
  body {
    margin: 0;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
  }

  * {
    box-sizing: border-box;
  }
  
  a, p, button, div, li, ol {
    font-family: 'OpenSans-SemiBold';
    letter-spacing: -0.015em;
  }
  
  h1, h2, .bold {
    font-family: 'OpenSans-ExtraBold';
    font-weight: bold;
    letter-spacing: -0.015em;
  } 
  
  h3, h4, h5 {
    font-family: 'OpenSans-SemiBold';
    letter-spacing: -0.015em;
  }
  
  section {
    width: 100%;
    border-top: 1px solid #e1e1e1;
    margin: 1rem 0;
  }
`;

import React, { useEffect } from "react";
import { ThemeContextState, AutoThemeState } from '../types';
import { useCookies } from 'react-cookie';

const defaultMode = 'light';

// Add transition styles to the document root
const addTransitionStyles = () => {
  const style = document.createElement('style');
  style.innerHTML = `
    * {
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease !important;
    }
  `;
  document.head.appendChild(style);
};

export const ManageThemeContext: React.Context<ThemeContextState> = React.createContext({
  auto: AutoThemeState.ON,
  count: 0,
  mode: defaultMode,
  toggleTheme: () => { },
  toggleAutoTheme: () => { },
  transitionTheme: 1
});

export const useTheme = () => React.useContext(ManageThemeContext);

export const ThemeManager = ({ children }) => {

  const [cookieTheme, setCookieTheme] = useCookies<any>(['theme']);

  const autoTheme = () => {
    if (cookieTheme.auto === undefined) {
      return AutoThemeState.ON;
    } else {
      return cookieTheme.auto;
    }
  }

  const currentTheme = () => {
    if (cookieTheme.mode === undefined) {
      return 'light';
    } else {
      if (autoTheme() === AutoThemeState.ON) {
        // Check for system preference first
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return 'dark';
        }
        
        // Fall back to time-based theme if no system preference
        const now = new Date();
        if (now.getHours() > 18 || now.getHours() <= 5) {
          return 'dark';
        } else {
          return 'light';
        }
      } else {
        return cookieTheme.mode;
      }
    }
  }

  const [themeState, setThemeState] = React.useState({
    auto: autoTheme(),
    count: 0,
    mode: currentTheme(),
    toggleTheme: () => { },
    toggleAutoTheme: () => { },
    transitionTheme: 1
  });

  const [transitionState, setTransitionState] = React.useState({
    transitionTheme: 1
  });

  // Add transition styles when component mounts
  useEffect(() => {
    addTransitionStyles();
  }, []);

  // Check for user's preferred color scheme
  useEffect(() => {
    if (cookieTheme.auto === AutoThemeState.ON && cookieTheme.mode === undefined) {
      const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark) {
        setThemeState(prev => ({ ...prev, mode: 'dark' }));
      }
    }
    
    // Add listener for system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      if (themeState.auto === AutoThemeState.ON) {
        setThemeState(prev => ({ ...prev, mode: e.matches ? 'dark' : 'light' }));
      }
    };
    
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [cookieTheme.auto, cookieTheme.mode, themeState.auto]);

  const toggleTheme = (): void => {
    let newTheme = (themeState.mode === 'light' ? `dark` : `light`);
    let newCount = themeState.count += 1;

    setCookieTheme('mode', newTheme);
    setCookieTheme('auto', AutoThemeState.OFF);
    setTransitionState({
      transitionTheme: 1
    });
    setThemeState(prevState => ({
      ...prevState,
      auto: AutoThemeState.OFF,
      mode: newTheme,
      count: newCount,
    }));
    setTimeout(() => {
      setTransitionState({
        transitionTheme: 0
      });
    }, 800);
  }

  const toggleAutoTheme = (newAuto: any) => {

    let newMode;
    let transitionTheme = 0;

    if (newAuto === AutoThemeState.ON) {
      const now = new Date();
      if (now.getHours() > 18 || now.getHours() <= 5) {
        newMode = 'dark';
      } else {
        newMode = 'light';
      }
    } else {
      newMode = themeState.mode;
    }

    if (newMode !== themeState.mode) {
      transitionTheme = 1;
    }

    let newCount = themeState.count += 1;

    setCookieTheme('auto', newAuto);
    setCookieTheme('mode', newMode);

    setTransitionState({
      transitionTheme: transitionTheme
    });
    setThemeState(prevState => ({
      ...prevState,
      auto: newAuto,
      mode: newMode,
      count: newCount
    }));
    setTimeout(() => {
      setTransitionState({
        transitionTheme: 0
      });
    }, 800);
  }

  return (
    <ManageThemeContext.Provider value={{
      auto: themeState.auto,
      count: themeState.count,
      mode: themeState.mode,
      toggleTheme: toggleTheme,
      toggleAutoTheme: toggleAutoTheme,
      transitionTheme: transitionState.transitionTheme
    }}>
      {children}
    </ManageThemeContext.Provider>
  );
}
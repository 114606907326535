export enum AutoThemeState {
  OFF = 'off',
  ON = 'on'
}

export interface ThemeContextState {
  auto: any;
  mode: string;
  toggleTheme (): void;
  toggleAutoTheme (newAuto?: any): void;
  count: number;
  transitionTheme: number;
}
export interface NotifierContextState {
  call (status?: string, message?: string): void;
  notifier?: string;
  status?: string;
  showLoading (message?: string): void;
  hideLoading (): void;
}

export interface ToggleProps {
  label: string
}

export interface ButtonProps {
  label: string;
  border?: boolean;
  variant?: 'primary' | 'secondary' | 'text';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
  iconOnly?: boolean;
  className?: string;
}

type Header_Theme = 'light' | 'dark';

export interface HeaderWrapper {
  headerState: Object;
  newColor: string;
  playTransition: boolean;
  theme: Header_Theme;
  showHeader: boolean;
  hideHeader: boolean;
}
import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { LoadingFallback } from './components/LoadingFallback';

// Lazy load page components
const About = lazy(() => import('./pages/About'));
const WhatIs = lazy(() => import('./pages/WhatIs'));
const Home = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));

export const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/what-is-a-construction-management-plan" element={<WhatIs />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </Suspense>
  );
}
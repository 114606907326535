import React from 'react';
import styled from 'styled-components';
import { containerWidths } from '../styles';
import { ButtonProps } from '../types';

export const ButtonPrimary: React.FC<ButtonProps> = (props) => {

  const StyledButton = styled.button`
    border: none;
    padding: 0.45em 1.20em;
    border-radius: 50px;
    font-size: 1.05rem;
    background: none;
    border: 2px solid #70af1a;
    cursor: pointer;
    color: #559004;
    font-weight: 700;
    outline: none;
    transition: all 0.15s;
    white-space: normal;
    text-align: center;
    line-height: 1.3;
    display: inline-block;
    width: auto;
    max-width: 100%;
    min-height: 44px;
    min-width: 44px;
    margin: 4px;

    @media(max-width: ${containerWidths.mobile}px) {
      font-size: 0.9rem;
      padding: 0.5em 1.2em;
    }

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: #447003;
        border-color: #559004;
        background-color: rgba(112, 175, 26, 0.05);
      }
    }

    &:active {
      background: none;
    }

    &:focus-visible {
      outline: 3px solid #447003;
      outline-offset: 2px;
    }
  `;

  return (
    <StyledButton>
      {props.label}
    </StyledButton>
  )
}